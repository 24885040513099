
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.item {
    svg {
        path {
            fill: var(--iconColor);
        }
    }
}

.mainSection {
    position: relative;

    .title {
        line-height: normal;
        @include handleAllBreakpoints(
            "font-size",
            "titleSize",
            "var(--global-sections-typography-title-size)"
        );
        @include handleAllBreakpoints(
            "color",
            "titleColor",
            "var(--global-sections-typography-title-color)"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "titleWeight",
            "var(--global-sections-typography-title-weight)"
        );
        @include handleAllBreakpoints(
            "text-align",
            "titleAlign",
            "var(--global-sections-typography-title-align)"
        );
        @include handleAllBreakpoints("margin", "titleMargin", "0 0 1.5rem 0");
    }

    .description {
        line-height: normal;
        @include handleAllBreakpoints(
            "font-size",
            "descriptionSize",
            "var(--global-sections-typography-description-size)"
        );
        @include handleAllBreakpoints(
            "color",
            "descriptionColor",
            "var(--global-sections-typography-description-color)"
        );
        @include handleAllBreakpoints(
            "font-weight",
            "descriptionWeight",
            "var(--global-sections-typography-description-weight)"
        );
        @include handleAllBreakpoints(
            "text-align",
            "descriptionAlign",
            "var(--global-sections-typography-description-align)"
        );
        @include handleAllBreakpoints(
            "margin",
            "descriptionMargin",
            "0 0 4.5rem 0"
        );
    }

    .grid {
        @include handleAllBreakpoints("gap", "gap", "5rem");

        display: grid;
        grid-template-columns: repeat(var(--grid-cols-sm), 1fr);
        @include md {
            grid-template-columns: repeat(var(--grid-cols-md), 1fr);
        }
        @include lg {
            grid-template-columns: repeat(var(--grid-cols-lg), 1fr);
        }
        @include xl {
            grid-template-columns: repeat(var(--grid-cols-xl), 1fr);
        }
    }

    .item {
        @include handleAllBreakpoints("background", "itemBackgroundColor");
        @include handleAllBreakpoints("box-shadow", "itemShadow");
        @include handleAllBreakpoints("border-radius", "itemBorderRadius");
        @include handleAllBreakpoints("border", "itemBorder");
        @include handleAllBreakpoints("padding", "itemPadding");

        .item-title {
            line-height: normal;
            @include handleAllBreakpoints("font-size", "itemTitleSize");
            @include handleAllBreakpoints(
                "color",
                "itemTitleColor",
                "var(--global-sections-typography-title-color)"
            );
            @include handleAllBreakpoints("font-weight", "itemTitleWeight");
            @include handleAllBreakpoints(
                "text-align",
                "itemAlign",
                "var(--global-sections-typography-title-align)"
            );
            @include handleAllBreakpoints("margin", "itemTitleMargin");
        }
        .item-href {
            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                @include handleAllBreakpoints(
                    "border-radius",
                    "itemBorderRadius"
                );
            }
        }
        p {
            line-height: normal;
            @include handleAllBreakpoints("font-size", "itemDescriptionSize");
            @include handleAllBreakpoints(
                "color",
                "itemDescriptionColor",
                "var(--global-sections-typography-description-color)"
            );
            @include handleAllBreakpoints(
                "font-weight",
                "itemDescriptionWeight"
            );
            @include handleAllBreakpoints(
                "text-align",
                "itemAlign",
                "var(--global-sections-typography-title-align)"
            );
            @include handleAllBreakpoints("margin", "itemDescriptionMargin");
        }

        .imageContainer {
            @include handleAllBreakpoints(
                "justify-content",
                "itemAlign",
                "var(--global-sections-typography-title-align)"
            );
            @include handleAllBreakpoints("margin", "imageMargin");
        }

        .image {
            @include handleAllBreakpoints("width", "imageWidth", "50px");
            @include handleAllBreakpoints("height", "imageHeight", "50px");
            @include handleAllBreakpoints("filter", "imageFilter");
        }
    }
}

.link {
    position: relative;
    &:hover {
        &::before {
            width: 100%;
        }
    }
    &::before {
        content: "";
        transition: width 0.3s ease-in-out;
        position: absolute;
        bottom: -10px;
        width: 0%;
        height: 3px;
        background-color: $contentintelligence-orange;
        z-index: -1;
        &:hover {
            width: 100%;
        }
    }
}

.listItem {
    @include handleAllBreakpoints("font-size", "listItemSize", "16px");
    @include handleAllBreakpoints("color", "listItemColor", "#000");
    @include handleAllBreakpoints("font-weight", "listItemWeight", "400");
    @include handleAllBreakpoints(
        "text-align",
        "listItemAlign::itemAlign",
        "center"
    );
    @include handleAllBreakpoints("margin", "listItemMargin", "0");
    @include handleAllBreakpoints("padding", "listItemPadding", "0");
}
